import React, { FC } from "react";
import styled from "styled-components";
import logo from "../assets/images/bat_centrum_dachowe_logo.png";
import { getBasicUrlLogoImage } from "../Helper";

type Props = {
  imageUrl: string;
};

const Logo: FC<Props> = ({ imageUrl }) => {
  return <LogoImage src={`${getBasicUrlLogoImage()}${imageUrl}`} />;
};
export default Logo;

const LogoImage = styled.img`
  height: 89px;
  max-width: 85%;
  object-fit: contain;
`;
