import React, { FC } from "react";
import styled from "styled-components";
import Logo from "./Logo";
import { IReportReturnModel } from "../Model";

type Props = {
  report: IReportReturnModel;
};

const PageHeader: FC<Props> = ({ report }) => {
  return (
    <Container>
      <PageHeaderContent>
        <PageHeaderContentRow>
          <PageHeaderColumn
            style={{
              display: "flex",
              alignItems: "flex-start",
            }}
          >
            <Logo imageUrl={report.logoUrl} />
          </PageHeaderColumn>
          <PageHeaderColumn
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                background: "#F5F5F5",
                padding: "16px",
                borderRadius: "8px",
                color: "#848080",
              }}
            >
              <div style={{ display: "flex", fontWeight: "bold" }}>
                Dnia: {new Date(report.created).toLocaleDateString("pl-PL")}.
              </div>
              <div style={{ display: "flex" }}>
                Kalkulacja ważna do:&nbsp;
                {new Date(report.expiryDate).toLocaleDateString("pl-PL")}
                <br />
                lub do czasu zmian cen przez producenta.
              </div>
            </div>
          </PageHeaderColumn>
        </PageHeaderContentRow>
        <PageHeaderContentRow style={{ height: "100%", paddingBottom: "8px" }}>
          <PageHeaderColumn style={{ width: "220px", display: "flex", alignItems: "center" }}></PageHeaderColumn>
          <PageHeaderColumn
            style={{ width: "282px", display: "flex", justifyContent: "center", alignItems: "flex-end" }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                fontSize: "18px",
                fontWeight: "bold",
                color: "#0555A0",
                textAlign: "center",
              }}
            >
              Kalkulacja pokrycia dachowego
              <br />
              wraz z elementami dodatkowymi
            </div>
          </PageHeaderColumn>
          <PageHeaderColumn
            style={{ width: "220px", display: "flex", alignItems: "flex-end", justifyContent: "flex-end" }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "right",
                background: "#F5F5F5",
                padding: "16px",
                borderRadius: "8px",
                color: "#848080",
                fontSize: "14px",
              }}
            >
              {report.projectNumber}
            </div>
          </PageHeaderColumn>
        </PageHeaderContentRow>
      </PageHeaderContent>
    </Container>
  );
};
export default PageHeader;

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 180px;
`;

const PageHeaderContent = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const PageHeaderContentRow = styled.div`
  display: flex;
  width: 100%;
`;

const PageHeaderColumn = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`;
